import React, { FC, useEffect, useMemo, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { loginSchemaFactory } from "../../validation-schemas/LoginSchema";
import { FormikProps, useFormik } from "formik";
import { LoginForm, loginInitialValues } from "../../domain/forms/LoginForm";
import { ErrorServiceFactory } from "../../services/ErrorService";
import { userServiceFactory } from "../../services/UserServiceImpl";
import FixedSimpleError from "../shared/FixedSimpleError";
import LoadingPanel from "../shared/LoadingPanel";
import { recaptchaSiteKey } from "../../Config";
import MainHeader from "../headers/main-header/MainHeader";
import FormInput from "../shared/FormInput";
import MainFooter from "../footer/MainFooter";
import { useRouterNavigate } from "../../hooks/useRouterNavigate";
import RouterLink from "../shared/RouterLink";
import { useAuth } from "../../context/AuthProvider";

interface LoginPageProps {}

const errorService = ErrorServiceFactory();
const userService = userServiceFactory();

const LoginPage: FC<LoginPageProps> = (_props) => {
    const { t } = useTranslation();
    const loginSchema = useMemo(() => loginSchemaFactory({ t }), [t]);
    const [error, setError] = useState<string>(null);
    const [load, setLoad] = useState(true);

    const { signIn } = useAuth();

    const navigate = useRouterNavigate();

    useEffect(() => {
        (async () => {
            const user = await userService.getCachedUser();
            setLoad(false);
            if (user?.id) {
                navigate("/consults");
            }
        })();
    }, []);

    const loginForm: FormikProps<LoginForm> = useFormik({
        enableReinitialize: true,
        initialValues: loginInitialValues,
        validationSchema: loginSchema.schema,
        onSubmit: async (values) => {
            try {
                setError(null);
                setLoad(true);
                const user = await signIn(values);
                setLoad(false);
                if (user?.id) {
                    navigate("/");
                } else {
                    setError(t("errors.standardError"));
                }
            } catch (err) {
                const nameError = errorService.getErrorTranslateKey(err);
                setError(nameError ? t(`errors.${nameError}`) : t("errors.standardError"));
                setLoad(false);
            }
        },
    });

    const recaptchaHandler = (value: string) => {
        loginForm.setFieldValue("recaptchaToken", value);
    };

    return (
        <>
            <title>
                {t("project.name")} - {t("login.common.loginLabel")}
            </title>
            <MainHeader />
            <div className="content content_margin-top">
                <LoadingPanel isVisible={load} />
                <form onSubmit={loginForm.handleSubmit}>
                    <div className="login__first">
                        <div className="log__first--top">
                            <div className="log__first--top_link">
                                <RouterLink path="/login">{t("login.common.loginLabel")}</RouterLink>
                            </div>
                            <div className="log__first--top_link active">
                                <RouterLink path="/registration">{t("login.common.registrationLabel")}</RouterLink>
                            </div>
                        </div>
                        <div className="log__first--headline">{t("login.common.emailLabel")}</div>
                        <div className="log__first--fields_wrap">
                            <div className="log__first--input">
                                <FormInput form={loginForm} schema={loginSchema} name="email" type="text" />
                            </div>
                        </div>
                        <div className="log__first--headline">{t("login.common.passwordLabel")}</div>
                        <div className="log__first--fields_wrap log__first--fields_marg">
                            <div className="log__first--input">
                                <FormInput form={loginForm} schema={loginSchema} name="password" type="password" />
                            </div>
                        </div>
                        <ReCAPTCHA onChange={recaptchaHandler} className="g-recaptcha" sitekey={recaptchaSiteKey} />
                        <button
                            disabled={!loginForm.isValid}
                            className="login_btn log__first--apply_text"
                            type="submit"
                        >
                            {t("login.common.submitButtonLabel")}
                        </button>
                        <RouterLink useAnchorElement className="log__first--wrong-forgotPassword" path="/reset">
                            {t("login.common.forgotPasswordLabel")}
                        </RouterLink>
                    </div>
                    <FixedSimpleError error={error} className="failed_input_info registration_form_error" />
                </form>
            </div>
            <MainFooter />
        </>
    );
};

export default LoginPage;
