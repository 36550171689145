export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const PICKER_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
export const EXPRESS_HOURS_DELTA = 3;
export const MIN_TIME_TO_CONCILIUM = 30;
export const TIME_POINT_DURATION_MIN = 30;

export const CONCILIUM_PATH = "concilium";

export const USER_CONSULTS = "user/consults";
export const SPEC_CONSULTS = "spec/consults";

export const CST_PATH = "customer-support-ticket";
export const TRANSACTION_PATH = "transaction";
export const EVENT_PATH = "event";

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE = 1;
export const GET_ALL_RECORDS = -1;

export const TRANSACTION_ID_LENGTH = 100;

export const DEFAULT_USER_PHOTO = "/static/images/default_user.png";

export const MIN_LABOR_AGE = 18;

export const ONE_MB_BYTES = 1048576;

export const FIVE_MB_BYTES = 5 * ONE_MB_BYTES;
export const SIXTY_MB_BYTES = 60 * ONE_MB_BYTES;

export const IMAGE_ALLOWED_EXTENSIONS_STR =
    '["jpg", "jpeg", "png", "bmp", "svg", "webp"]';
export const TEXT_ALLOWED_EXTENSIONS_STR =
    '["txt", "csv", "pdf", "doc", "docx"]';
export const VIDEO_ALLOWED_EXTENSIONS_STR = '["mp4", "mov", "wmv"]';

export const DEFAULT_MAX_FILES_COUNT = 5;

export const DEFAULT_TRANSACTION_TIMEOUT_SEC = 600;

export const MILLISECONDS_IN_ONE_SECOND = 1000;

export const DEFAULT_CONCILIUM_STARTING_SECOND = 1;

export const DEFAULT_CHECK_STATUSES_INTERVAL_MS = 60000;

export const MIN_REGISTRATION_AGE = 18;

export const DEFAULT_LANGUAGE = "ru";

export const SLIDER_RUNNER_SPEED_MS = 7000;

export const FOOTER_TYPES_LIMIT = 30;
export const FOOTER_TYPES_COLUMN_LIMIT = 10;

export const MIN_DESKTOP_WIDTH = 744;
