import moment from "moment";
import momentTimezone from "moment-timezone";
import {
    DEFAULT_CHECK_STATUSES_INTERVAL_MS,
    MILLISECONDS_IN_ONE_SECOND,
    PICKER_DATETIME_FORMAT,
} from "../domain/constants/Constants";

export function getDateStr(time: moment.Moment): string {
    if (!time) {
        return "";
    }
    return `${time.date() < 10 ? "0" + time.date() : time.date()}.${
        time.month() + 1 < 10 ? "0" + (time.month() + 1) : time.month() + 1
    }.${time.year()}`;
}

export function getMinutesStr(time: moment.Duration | moment.Moment): string {
    const minutes = time.minutes();
    const seconds = time.seconds();
    return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
}

export function getPickerCurrentTime(): string {
    return moment().format(PICKER_DATETIME_FORMAT);
}

export function getSyncInterval(
    defaultIntervalMs: number,
    serverTimeDifferenceMs: number,
    currentTimeSecond: number,
    estimatedSecond: number
): number {
    defaultIntervalMs = defaultIntervalMs || DEFAULT_CHECK_STATUSES_INTERVAL_MS;
    const normalizedServerTimeDifferenceMs = serverTimeDifferenceMs % defaultIntervalMs;
    const millisecondsUntilRefresh =
        ((defaultIntervalMs -
            (currentTimeSecond * MILLISECONDS_IN_ONE_SECOND - estimatedSecond * MILLISECONDS_IN_ONE_SECOND)) %
            defaultIntervalMs) -
        normalizedServerTimeDifferenceMs;
    return millisecondsUntilRefresh < 0
        ? defaultIntervalMs + (millisecondsUntilRefresh % defaultIntervalMs)
        : millisecondsUntilRefresh;
}

export function getTimezones() {
    return momentTimezone.tz.names();
}

export function checkAge(value: string, minAge: number) {
    const birthday = moment(value, "YYYY-MM-DD", true);

    if (!birthday.isValid()) {
        return false;
    }

    const today = moment();
    const yearsDiff = today.diff(birthday, "years");
    const isOver =
        yearsDiff > minAge ||
        (yearsDiff === minAge && today.month() > birthday.month()) ||
        (yearsDiff === minAge && today.month() === birthday.month() && today.date() >= birthday.date());

    return isOver;
}
