import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import RouterLink from "../shared/RouterLink";

interface RegistrationSuccessModalProps {
    success: boolean;
}

const RegistrationSuccessModal: FC<RegistrationSuccessModalProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Popup open={props.success} modal overlayStyle={{ zIndex: 999999 }}>
            {() => (
                <div className="content content_margin-top">
                    <div className="modal modal__no-padding active" id="regtwo">
                        <div className="modal__body modal__body__registration">
                            <div className="reg__two">
                                <div className="reg__two--top">
                                    <div className="reg__two--heading">{t("registration.successModal.title")}</div>
                                    <div className="reg__two--subheading">{t("registration.successModal.info")}</div>
                                </div>
                                <div className="reg__two--bottom">
                                    <RouterLink className="reg__two--continue reg__two--continue_text-modal" path="/">
                                        {t("registration.successModal.mainLabel")}
                                    </RouterLink>
                                    <RouterLink
                                        className="reg__two--continue reg__two--continue_text-modal"
                                        path="/login"
                                    >
                                        {t("registration.successModal.loginLabel")}
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default RegistrationSuccessModal;
